define("discourse/plugins/x-discourse-docs-community/discourse/templates/connectors/after-filter-title/community-filter", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="docs-item docs-community-filter">
    <h4>
      {{i18n "x-docs.community_topics"}}
    </h4>
  
    <label class="checkbox-label docs-item">
      {{input
        type="checkbox"
        checked=(readonly communityTopics)
        change=(action "changeChecked" value="target.checked")
      }}
    </label>
  </div>
  */
  {
    "id": "CSNOVb+j",
    "block": "[[[10,0],[14,0,\"docs-item docs-community-filter\"],[12],[1,\"\\n  \"],[10,\"h4\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"x-docs.community_topics\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"label\"],[14,0,\"checkbox-label docs-item\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",[28,[37,2],[[30,0,[\"communityTopics\"]]],null],[28,[37,3],[[30,0],\"changeChecked\"],[[\"value\"],[\"target.checked\"]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `communityTopics` property path was used in the `discourse/plugins/x-discourse-docs-community/discourse/templates/connectors/after-filter-title/community-filter.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.communityTopics}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"input\",\"readonly\",\"action\"]]",
    "moduleName": "discourse/plugins/x-discourse-docs-community/discourse/templates/connectors/after-filter-title/community-filter.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});