define("discourse/plugins/x-discourse-docs-community/discourse/x-docs-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("knowledge-base", {
      path: "/knowledge-base"
    }, function () {
      this.route("index", {
        path: "/"
      });
    });
  }
});