define("discourse/plugins/x-discourse-docs-community/discourse/controllers/knowledge-base-index", ["exports", "discourse/plugins/discourse-docs/discourse/controllers/docs-index", "@ember/object", "@ember/application"], function (_exports, _docsIndex, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _docsIndex.default.extend(dt7948.p({
    returnToList() {
      this.set("selectedTopic", null);
      (0, _application.getOwner)(this).lookup("router:main").transitionTo("knowledge-base");
    }
  }, [["method", "returnToList", [_object.action]]]));
});