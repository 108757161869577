define("discourse/plugins/x-discourse-docs-community/discourse/initializers/x-docs-initializer", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/models/topic"], function (_exports, _object, _ajax, _pluginApi, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'x-docs-initializer',
    initialize(container) {
      const siteSettings = container.lookup('site-settings:main');
      (0, _pluginApi.withPluginApi)('0.11.0', api => {
        api.modifyClassStatic('model:docs', {
          pluginId: 'x-discourse-docs-community',
          list(params) {
            let filters = [];
            if (params.filterCategories) {
              filters.push(`category=${params.filterCategories}`);
            }
            if (params.filterTags) {
              filters.push(`tags=${params.filterTags}`);
            }
            if (params.filterSolved) {
              filters.push(`solved=${params.filterSolved}`);
            }
            if (params.searchTerm) {
              filters.push(`search=${params.searchTerm}`);
            }
            if (params.ascending) {
              filters.push('ascending=true');
            }
            if (params.orderColumn) {
              filters.push(`order=${params.orderColumn}`);
            }
            if (params.page) {
              filters.push(`page=${params.page}`);
            }
            if (params.selectedTopic) {
              filters.push(`topic=${params.selectedTopic}`);
            }
            if (params.filterStaff) {
              filters.push(`communityTopics=${params.filterStaff}`);
            }

            // switch back to `/knowledge-base.json` after fix
            return (0, _ajax.ajax)(`/knowledge-base.json?${filters.join('&')}`).then(data => {
              data.topics.topic_list.topics = data.topics.topic_list.topics.map(topic => _topic.default.create(topic));
              data.topic = _topic.default.create(data.topic);
              return data;
            });
          }
        });
        api.modifyClass('route:docs-index', {
          pluginId: 'x-discourse-docs-community',
          queryParams: {
            ascending: {
              refreshModel: true
            },
            filterCategories: {
              refreshModel: true
            },
            filterTags: {
              refreshModel: true
            },
            filterSolved: {
              refreshModel: true
            },
            orderColumn: {
              refreshModel: true
            },
            selectedTopic: {
              refreshModel: true
            },
            searchTerm: {
              replace: true,
              refreshModel: true
            },
            filterStaff: {
              refreshModel: true
            }
          }
        });
        api.modifyClass('controller:docs-index', dt7948.p({
          pluginId: 'x-discourse-docs-community',
          queryParams: {
            ascending: 'ascending',
            filterCategories: 'category',
            filterTags: 'tags',
            filterSolved: 'solved',
            orderColumn: 'order',
            searchTerm: 'search',
            selectedTopic: 'topic',
            filterStaff: 'communityTopics' // added this key/value
          },
          filterStaff: false,
          showCommunityTopics(communityTopics) {
            this.set('filterStaff', communityTopics);
          }
        }, [["method", "showCommunityTopics", [_object.action]]]));
        api.registerConnectorClass('after-filter-title', 'community-filter', dt7948.p({
          changeChecked(communityTopics) {
            this.showCommunityTopics(communityTopics);
          },
          setupComponent(attrs) {
            this.set('communityTopics', attrs.filterStaff);
            this.set('showCommunityTopics', attrs.showCommunityTopics);
          }
        }, [["method", "changeChecked", [_object.action]]]));
        api.decorateWidget('hamburger-menu:generalLinks', () => {
          return {
            route: 'knowledge-base',
            label: 'x-docs.title',
            className: 'knowledge-base-link'
          };
        });
        if (siteSettings.x_docs_add_to_top_menu) {
          api.addNavigationBarItem({
            name: 'knowledge-base',
            displayName: I18n.t('x-docs.title'),
            href: '/knowledge-base'
          });
        }
      });
    }
  };
});